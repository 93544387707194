// Import images
import indexImage from './images/AERIAL_CGI_HR_Web.jpg'

import p1Logo from './images/P4P-Logo-normal.svg'
import p1Bg from './images/the-grove-bg.jpg'
import p1Image from './images/AERIAL_Oaks.jpg'

import p2Logo from './images/P4P-Logo-white.svg'
import p2Bg from './images/the-oaks-bg.jpg'
import p2Image from './images/AERIAL_Grove.jpg'

interface Development {
    name: string,
    logoPath: string,
    bgPath: string,
    imagePath: string,
    url: string,
    status: string,
}

const config: {
    indexImagePath: string,
    developments: Array<Development>
} = {
    indexImagePath: indexImage,
    developments: [
        {
            name: 'Demo Phase 1 Active',
            logoPath: p1Logo,
            bgPath: p1Bg,
            imagePath: p1Image,
            url: 'https://demo-phase-1.placesforpeople.think-online.co.uk/site',
            status: 'Available'
        },
        {
            name: 'Demo Phase 2 Coming soon',
            logoPath: p2Logo,
            bgPath: p2Bg,
            imagePath: p2Image,
            url: 'https://demo-phase-2.placesforpeople.think-online.co.uk/site',
            status: 'Coming Soon'
        },
    ]
}

export type {Development}
export {config};