// Styles
import styles from './Label.module.scss';

// Vender
import cx from 'classnames';

interface LabelProps {
  text: string,
  style: 'light' | 'dark'
}

function Label({text, style} : LabelProps) {

  return (
      <div 
        className={cx(styles.Label, {[styles.Dark] : style === 'dark'})}
      >
        {text}
      </div>
  );
}

export default Label;
